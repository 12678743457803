import request from '@/utils/request'
//权限一览(总台)
export function getAuthAll(query) {
    return request({
      url: '/orgAuthGinseng/getAuthList',
      method: 'get',
      params:query
    })
  }
//添加权限信息
export function addAuthInfo(data) {
    return request({
      url: '/orgAuthGinseng/addAuthInfo',
      method: 'post',
     data
    })
  }

//更新权限信息
export function editAuthInfo(data) {
    return request({
      url: '/orgAuthGinseng/editAuthInfo',
      method: 'post',
     data
    })
  }
  //根据主键查询权限详情
  export function getAuthInfoById(query) {
    return request({
      url: '/orgAuthGinseng/getAuthInfoById',
      method: 'get',
      params:query
    })
  }
  //删除权限信息
  export function deleteAuthInfo(query) {
    return request({
      url: '/orgAuthGinseng/deleteAuthInfo',
      method: 'DELETE',
      params:query
    })
  }
  //权限一览(总台)
  export function getAll() {
    return request({
      url: '/orgAuthGinseng/getAuthAll',
      method: 'get',
   
    })
  }
  